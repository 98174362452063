import React, { useState, useEffect, useCallback } from "react";
import "./Banner.scss";
import BannerContent from './BannerContent';

const TEXT = 'Listen to the Bad Comedy Podcast on Patreon';
const LINK = 'https://www.patreon.com/badcomedy/'

const Banner = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const displayBanner = useCallback((timer) => {
    setIsDisplayed(true);
    clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayed]);
  useEffect(() => {
    const timer = setInterval(() => displayBanner(timer), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayed]);
  const bannerClassName = isDisplayed ? 'Banner displayBanner' : 'Banner'

  return (
    <div className={bannerClassName}>
      <BannerContent type='pod' text={TEXT} link={LINK}/>
    </div>
  )
}

  export default Banner;

