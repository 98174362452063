import { useState, useEffect } from "react";

import TicketIcon from './TicketIcon'
import PodcastIcon from './PodcastIcon';

// prop types: { type: 'ticket' | 'pod' | null; text: string; }

const BannerContent = ({type = null, text = '', link = ''}) => {
  const getWindowWidth = () => window.innerWidth
  const [windowWidth, setWindowDimensions] = useState(getWindowWidth());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowWidth());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const iconSize = windowWidth > 768 ? "20" : "10"

  return (
    <a
      className='ticketLink'
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {type === 'ticket' ? <TicketIcon width={iconSize} height={iconSize}/> : null}
      {type === 'pod' ? <PodcastIcon width={iconSize} height={iconSize}/> : null}
      <h3 className='bannerText'>{text}</h3>
    </a>
  )
}

export default BannerContent
